
import {
  Emit,
  Prop,
  Component,
  Mixins,
  Watch,
  InjectReactive
} from 'vue-property-decorator';
import Vue from 'vue';
import SnackbarMixin from '@/mixins/snackbarMixin';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import ProfileDetailsBaseComponent from './ProfileDetailsBaseComponent.vue';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { getSectionStatus } from '@/util/getSectionStatus';
import { ESectionStatus } from '@/enums/ESectionStatus';
import { convertToKeyValueObj } from '@/util/object/convertToKeyValueObj';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import generateScrollToCallback from '@/util/generateScrollToCallback';
import UpdateStickyComponent from '@/components/UpdateStickyComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { EExitReasonTypes } from '@/enums/EExitReasonTypes';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EmailModalComponent from '@/components/Modals/Email/EmailModalComponent.vue';
import OutProcessingPackageEmailComponent from '@/components/Email/OutProcessingPackageEmailComponent.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const sections = convertToKeyValueObj({
  STUDENT_SERVICES: 'Student Services',
  PERSONNEL: 'Personnel'
});

class Model extends BaseCandidate {
  statusFunction: (fields: unknown[]) => ESectionStatus;

  get studentServicesSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.outProcessingLeavereconrequested,
      this.outProcessingLeaveenddate,
      this.outProcessingLeavebalance,
      this.outProcessingAf988partone,
      this.outProcessingFinalmodapproved,
      this.outProcessingSurveysent,
      this.outProcessingSurveyreceived,
      this.outProcessingExitreason,
      this.outProcessingFinal
    ]);
  }

  get personnelSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.outProcessingPackagesent,
      this.outProcessingPackagereceived,
      this.outProcessingSent1288,
      this.outProcessingReceived1288,
      this.outProcessingRsc,
      this.outProcessingAssignmentorder,
      this.outProcessingGtcc,
      this.outProcessingDiss,
      this.outProcessingDetachdtsaccount,
      this.outProcessingDecorationdate,
      this.outProcessingAan,
      this.outProcessingMilpdsaction1288,
      this.outProcessingDetachdtsaccount
    ]);
  }

  constructor(
    partial?: Partial<BaseCandidate>,
    statusFunction = getSectionStatus
  ) {
    super(partial);
    this.statusFunction = statusFunction;
  }
}

@Component<OutProcessingPage>({
  components: {
    DateSelectorComponent,
    ProfileDetailsBaseComponent,
    UpdateStickyComponent,
    DropdownComponent,
    ButtonComponent,
    EmailModalComponent,
    OutProcessingPackageEmailComponent,
    FontAwesomeIcon
  }
})
export default class OutProcessingPage extends Mixins(Vue, SnackbarMixin) {
  @Prop({ required: true })
  applicant!: Applicant;

  @InjectReactive('isUpdating')
  isUpdating!: boolean;

  model = new Model();
  created(): void {
    this.model = new Model(this.applicant);
  }

  exitReasonDropdownOptions = getInputOptions(EExitReasonTypes);
  sections = sections;

  $refs!: {
    sendEmailModal: EmailModalComponent;
  };

  @Watch('model')
  @Watch('applicant')
  setSectionStatus(): void {
    const { STUDENT_SERVICES, PERSONNEL } = this.sections;

    this.$store.dispatch('profileSideNavigationModule/setSections', [
      {
        header: 'Out-Processing',
        entries: [
          {
            text: STUDENT_SERVICES.label,
            status: this.model.studentServicesSectionStatus,
            onClick: generateScrollToCallback(STUDENT_SERVICES.key)
          },
          {
            text: PERSONNEL.label,
            status: this.model.personnelSectionStatus,
            onClick: generateScrollToCallback(PERSONNEL.key)
          }
        ]
      }
    ]);
  }

  openModal(): void {
    this.$refs.sendEmailModal.open();
    return;
  }

  @Emit('updateApplicant')
  updateApplicant(): Model {
    return this.model;
  }
}
