
import ButtonComponent from '@/components/ButtonComponent.vue';
import StickyContainerComponent from '@/components/StickyContainerComponent.vue';
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';

@Component<UpdateStickyComponent>({
  components: {
    ButtonComponent,
    StickyContainerComponent
  }
})
export default class UpdateStickyComponent extends Vue {
  @Prop({ required: true })
  isUpdating!: boolean;

  @Emit('click')
  clickHandler(): void {
    return;
  }
}
