var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("sticky-container-component", [
    _c(
      "div",
      {
        staticClass:
          "flex justify-center p-4 mx-4 bg-white border border-gray-300 rounded shadow-xl",
      },
      [
        _c(
          "button-component",
          {
            staticClass: "flex items-center",
            class: { "opacity-50": _vm.isUpdating },
            attrs: {
              type: "submit",
              textOrBorderColor: "white",
              bg: "val-button-blue",
              disabled: _vm.isUpdating,
            },
            on: { click: _vm.clickHandler },
          },
          [
            _vm._v("Update Profile "),
            _vm.isUpdating
              ? _c("font-awesome-icon", {
                  staticClass:
                    "w-full h-full ml-2 transform -rotate-180 animate-spin",
                  style: { color: "white" },
                  attrs: { icon: ["fas", "rotate"] },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }