var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "profile-details-base-component",
    { attrs: { title: "Out Processing" } },
    [
      _c(
        "div",
        { staticClass: "mt-4 mb-10 form-input-container" },
        [
          _c("date-selector-component", {
            attrs: { label: "1288 (EDCSA)" },
            model: {
              value: _vm.model.outProcessingEdcsa1288,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingEdcsa1288", $$v)
              },
              expression: "model.outProcessingEdcsa1288",
            },
          }),
          _c(
            "button-component",
            {
              staticClass: "flex items-center justify-center mt-3 h-9",
              attrs: { textOrBorderColor: "white", bg: "val-button-blue" },
              on: { click: _vm.openModal },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-4",
                attrs: { icon: ["fas", "envelope"] },
              }),
              _vm._v("Send Out Pro Package"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "h3",
        {
          staticClass: "section-header",
          attrs: { id: _vm.sections.STUDENT_SERVICES.key },
        },
        [_vm._v(" " + _vm._s(_vm.sections.STUDENT_SERVICES.label) + " ")]
      ),
      _c(
        "div",
        { staticClass: "mb-10 form-input-container gap-y-6" },
        [
          _c("date-selector-component", {
            attrs: { label: "Leave Recon Requested" },
            model: {
              value: _vm.model.outProcessingLeavereconrequested,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingLeavereconrequested", $$v)
              },
              expression: "model.outProcessingLeavereconrequested",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Leave End Date" },
            model: {
              value: _vm.model.outProcessingLeaveenddate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingLeaveenddate", $$v)
              },
              expression: "model.outProcessingLeaveenddate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Leave Balance" },
            model: {
              value: _vm.model.outProcessingLeavebalance,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingLeavebalance", $$v)
              },
              expression: "model.outProcessingLeavebalance",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "AFF 988 Part 1" },
            model: {
              value: _vm.model.outProcessingAf988partone,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingAf988partone", $$v)
              },
              expression: "model.outProcessingAf988partone",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Final MOD Approved" },
            model: {
              value: _vm.model.outProcessingFinalmodapproved,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingFinalmodapproved", $$v)
              },
              expression: "model.outProcessingFinalmodapproved",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Survey Sent" },
            model: {
              value: _vm.model.outProcessingSurveysent,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingSurveysent", $$v)
              },
              expression: "model.outProcessingSurveysent",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Survey Received" },
            model: {
              value: _vm.model.outProcessingSurveyreceived,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingSurveyreceived", $$v)
              },
              expression: "model.outProcessingSurveyreceived",
            },
          }),
          _c("dropdown-component", {
            attrs: {
              label: "Exit Reason",
              values: _vm.exitReasonDropdownOptions,
            },
            model: {
              value: _vm.model.outProcessingExitreason,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingExitreason", $$v)
              },
              expression: "model.outProcessingExitreason",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Final" },
            model: {
              value: _vm.model.outProcessingFinal,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingFinal", $$v)
              },
              expression: "model.outProcessingFinal",
            },
          }),
        ],
        1
      ),
      _c(
        "h3",
        {
          staticClass: "section-header",
          attrs: { id: _vm.sections.PERSONNEL.key },
        },
        [_vm._v(" " + _vm._s(_vm.sections.PERSONNEL.label) + " ")]
      ),
      _c(
        "div",
        { staticClass: "mb-10 form-input-container gap-y-6" },
        [
          _c("date-selector-component", {
            attrs: { label: "Package Sent" },
            model: {
              value: _vm.model.outProcessingPackagesent,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingPackagesent", $$v)
              },
              expression: "model.outProcessingPackagesent",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Package Received" },
            model: {
              value: _vm.model.outProcessingPackagereceived,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingPackagereceived", $$v)
              },
              expression: "model.outProcessingPackagereceived",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "1288 Sent" },
            model: {
              value: _vm.model.outProcessingSent1288,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingSent1288", $$v)
              },
              expression: "model.outProcessingSent1288",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "1288 Received" },
            model: {
              value: _vm.model.outProcessingReceived1288,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingReceived1288", $$v)
              },
              expression: "model.outProcessingReceived1288",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "RSC" },
            model: {
              value: _vm.model.outProcessingRsc,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingRsc", $$v)
              },
              expression: "model.outProcessingRsc",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Assignment Order" },
            model: {
              value: _vm.model.outProcessingAssignmentorder,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingAssignmentorder", $$v)
              },
              expression: "model.outProcessingAssignmentorder",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "GTCC" },
            model: {
              value: _vm.model.outProcessingGtcc,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingGtcc", $$v)
              },
              expression: "model.outProcessingGtcc",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "DISS" },
            model: {
              value: _vm.model.outProcessingDiss,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingDiss", $$v)
              },
              expression: "model.outProcessingDiss",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Detach DTS Account" },
            model: {
              value: _vm.model.outProcessingDetachdtsaccount,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingDetachdtsaccount", $$v)
              },
              expression: "model.outProcessingDetachdtsaccount",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Decoration Date" },
            model: {
              value: _vm.model.outProcessingDecorationdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingDecorationdate", $$v)
              },
              expression: "model.outProcessingDecorationdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "AAN" },
            model: {
              value: _vm.model.outProcessingAan,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingAan", $$v)
              },
              expression: "model.outProcessingAan",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "1288 MILPDS Action" },
            model: {
              value: _vm.model.outProcessingMilpdsaction1288,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingMilpdsaction1288", $$v)
              },
              expression: "model.outProcessingMilpdsaction1288",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "DD 214" },
            model: {
              value: _vm.model.outProcessingDd214submitteddate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "outProcessingDd214submitteddate", $$v)
              },
              expression: "model.outProcessingDd214submitteddate",
            },
          }),
        ],
        1
      ),
      _c("update-sticky-component", {
        attrs: { isUpdating: _vm.isUpdating },
        on: { click: _vm.updateApplicant },
      }),
      _c(
        "email-modal-component",
        {
          ref: "sendEmailModal",
          attrs: {
            title: "Out Pro Package",
            subject: "340 UFT Out Processing",
            to: _vm.model.contactInformationEmail,
          },
        },
        [_c("out-processing-package-email-component")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }